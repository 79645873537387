<template>
  <Dropdown
    v-model="selectedValue"
    :options="options"
    :option-label="
      typeof options[0] === 'object' ? (optionLabel ?? 'label') : undefined
    "
    :option-value="
      typeof options[0] === 'object' ? (optionValue ?? 'value') : undefined
    "
    :placeholder="placeholder"
    :show-clear="clearable"
  >
    <template
      v-if="useIndicatorDots"
      #option="{ option }"
    >
      <span class="flex items-center gap-2">
        <AppIndicatorDot
          :class="option.color ?? 'text-current'"
          class="bg-current"
        />
        <span>{{ option.label }}</span>
      </span>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import Dropdown from 'primevue/dropdown'

const props = defineProps({
  modelValue: {
    type: String as PropType<string | null>,
    required: false,
    default: null,
  },
  options: {
    type: Array,
    required: true,
    default: () => [],
  },
  optionLabel: {
    type: String,
    required: false,
    default: null,
  },
  optionValue: {
    type: String,
    required: false,
    default: null,
  },
  placeholder: {
    type: String,
    required: false,
    default: null,
  },
  clearable: {
    type: Boolean,
    required: false,
    default: false,
  },
  useIndicatorDots: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue'])

const selectedValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})
</script>
