import { defineStore } from 'pinia'
import * as Sentry from '@sentry/vue'
import { useRouteQuery } from '@vueuse/router'
import { AuthoringStatus } from '~/types/AuthoringStatus'
import {
  InteractionState,
  type EmailMessage,
  type FileMessage,
  type Interaction,
  type InteractionLog,
  type Log,
  type TextMessage,
  type Thread,
} from '~/types/Interaction'
import {
  type PopulatedSimulation,
  SimulationBackgroundOption,
} from '~/types/Simulation'
import { useChatStore } from '~/stores/chat'
import { useEmailStore } from '~/stores/email'
import { useCandidateStore } from '~/stores/candidate'
import { StateError } from '~/errors/StateError'

export const useInteractionControlStore = defineStore(
  'interactionController',
  () => {
    // OTHER SERVICES =================================================================================
    const SimulationService = simulationService()
    const InteractionService = interactionService()
    const { addToast } = useToastNotifications()

    // OTHER STORES =================================================================================
    const candidateStore = useCandidateStore()

    // !TEMP FOR DEMO
    const queryDemo = useRouteQuery('demo')
    const useIframeDemo = computed(
      () => queryDemo.value !== null && queryDemo.value === 'true',
    )

    const testIdsInQuery = useRouteQuery('t', null, {
      transform: (val) =>
        (typeof val === 'string' ? [val] : val)?.filter((val) => !!val),
    })

    const assessmentIdInQuery = useRouteQuery('a', null, {
      transform: (val) =>
        (typeof val === 'string' ? [val] : val)?.filter((val) => !!val),
    })

    const sessionId = ref<string | null>(null)

    const simulation = ref<PopulatedSimulation | null>(null)
    const simulationType = computed(
      () => simulation.value?.type?.split(' ')[0] || null,
    )
    const interaction = ref<Interaction | null>(null)
    const environment = computed(() => {
      return simulation.value?.environment ?? null
    })

    const isInteractionReady = computed(() => {
      if (!interaction.value) return false

      return interaction.value.isReady
    })
    const thread = ref<Thread>([])
    const interactionState = ref<InteractionState>(InteractionState.NotStarted)
    const interactionBackground = computed<SimulationBackgroundOption>(() => {
      if (!simulation.value?.background) return SimulationBackgroundOption.None

      return simulation.value.background
    })

    const isPreviewMode = ref(false)

    const isLoading = ref(false)
    const loadingMessage = ref('')
    const hasError = ref(false)
    const errorMessage = ref('')

    async function refreshInteraction() {
      if (!interaction.value) return

      const { data, error } = await InteractionService.getInteractionById(
        interaction.value.simulationId,
        interaction.value.id,
      )

      if (error !== null) {
        console.error('Error refreshing interaction:', error)
        return
      }

      interaction.value = data
    }

    async function initInteraction(simulationId: string, previewMode: boolean) {
      isLoading.value = true
      isPreviewMode.value = previewMode
      interactionState.value = InteractionState.NotStarted

      $reset()

      // 1: FETCH SIMULATION ========================================
      loadingMessage.value = 'Fetching simulation...'
      const { data: simulationData, error: simulationError } =
        await SimulationService.getPopulatedSimulationById(simulationId)

      if (simulationError !== null) {
        errorMessage.value = 'Failed to fetch simulation'
        hasError.value = true
        isLoading.value = false
        return
      }

      simulation.value = simulationData

      // 2: CHECK SIM STATUS ========================================
      loadingMessage.value = 'Checking simulation status...'
      // Only allow published or (unpublished but preview) sims
      // Inversely, don't allow not ready, or not published and not in preview
      if (
        simulation.value.authoringStatus !== AuthoringStatus.Ready ||
        (!simulation.value.isPublished && !isPreviewMode.value)
      ) {
        errorMessage.value =
          'This simulation is unavailable because it is not published.'
        hasError.value = true
        isLoading.value = false
        return
      }

      // 3: LOAD CANDIDATE DATA ==============================
      await candidateStore.loadCandidateData()

      if (candidateStore.candidate === null) {
        errorMessage.value = 'Could not load candidate data'
        hasError.value = true
        isLoading.value = false
        return
      }

      // 4: POST INTERACTION DATA ===================================
      loadingMessage.value = 'Loading interaction data...'

      const { data: sessionData, error: sessionError } =
        await InteractionService.getInteractionSession(
          simulationId,
          candidateStore.candidate.id,
          isPreviewMode.value || useIframeDemo.value,
          testIdsInQuery.value ?? [],
          assessmentIdInQuery.value ?? [],
        )

      if (sessionError !== null) {
        errorMessage.value = 'There was an error loading interaction data.'
        hasError.value = true
        isLoading.value = false
        return
      }

      interaction.value = sessionData.interaction

      sessionId.value = sessionData.sessionId

      await refreshInteraction()

      if (!isInteractionReady.value) {
        errorMessage.value = 'Could not load interaction'
        hasError.value = true
        isLoading.value = false
        return
      }

      // 5: CHECK CAN PROCEED (RETRY) ==============================
      const { data: verificationResponse, error } =
        await InteractionService.verifyProceed(
          interaction.value.simulationId,
          interaction.value.id,
          interaction.value.candidateId!,
        )

      if (error !== null) {
        errorMessage.value = 'Could not verify candidate data'
        hasError.value = true
        isLoading.value = false
        return
      }

      if (!verificationResponse) {
        errorMessage.value =
          'The simulation cannot be attempted more than once.'
        hasError.value = true
        isLoading.value = false
        return
      }

      // 6: INIT CHAT/EMAIL STORE ==========================================
      if (simulationType.value === 'Chat') {
        const chatStore = useChatStore()

        chatStore.initChatStore()
      }

      if (simulationType.value === 'Email') {
        const emailStore = useEmailStore()

        emailStore.initEmailStore()
      }

      isLoading.value = false
      hasError.value = false
    }

    async function startInteraction() {
      if (!interaction.value) {
        const error = new StateError(
          'Could not start interaction. No interaction data.',
        )
        Sentry.captureException(error)
        return {
          error,
        }
      }

      if (!sessionId.value) {
        const error = new StateError(
          'Could not start interaction. No session ID.',
        )
        Sentry.captureException(error)
        return {
          error,
        }
      }

      const { data, error } = await InteractionService.startInteractionSession(
        interaction.value.simulationId,
        sessionId.value,
      )

      if (error !== null) {
        Sentry.captureException(error)
        return { error }
      }

      interactionState.value = InteractionState.InProgress

      thread.value = data

      return { error: null }
    }

    async function continueInteraction() {
      if (!interaction.value) {
        const error = new StateError(
          'Could not continue interaction. No interaction data.',
        )
        Sentry.captureException(error)
        return {
          error,
        }
      }

      if (!sessionId.value) {
        const error = new StateError(
          'Could not start interaction. No session ID.',
        )
        Sentry.captureException(error)
        return {
          error,
        }
      }

      const { data, error } =
        await InteractionService.continueInteractionSession(
          interaction.value.simulationId,
          sessionId.value,
          thread.value,
        )

      if (error !== null) {
        Sentry.captureException(error)
        return { error }
      }

      thread.value = data

      checkForCompletion()

      return { error: null }
    }

    function addCompleteToast() {
      addToast({
        type: 'interaction-end',
        message:
          'A message to satisfy TypeScript. This message should never be seen.',
      })
    }

    function addTextMessageToThread(message: TextMessage) {
      thread.value.push(message)
    }

    function addFileMessageToThread(message: FileMessage) {
      thread.value.push(message)
    }

    function addEmailMessageToThread(message: EmailMessage) {
      thread.value.push(message)
    }

    function checkForCompletion() {
      if (thread.value.length === 0) {
        interactionState.value = InteractionState.NotStarted
        return
      }

      const logs = thread.value.filter((entry) => entry.type === 'log') as Log[]
      const interactionLogs = logs.filter(
        (log: Log) => log.logType === 'interaction',
      ) as InteractionLog[]

      if (interactionLogs.some((log: InteractionLog) => log.event === 'end')) {
        interactionState.value = InteractionState.Complete
        addCompleteToast()
      }
    }

    function closeInteraction() {
      interactionState.value = InteractionState.Closed
    }

    function $reset() {
      simulation.value = null
      interaction.value = null
      thread.value = []
    }

    return {
      simulation,
      simulationType,
      interaction,
      isLoading,
      loadingMessage,
      hasError,
      errorMessage,
      $reset,
      initInteraction,
      isInteractionReady,
      thread,
      startInteraction,
      continueInteraction,
      addTextMessageToThread,
      addFileMessageToThread,
      addEmailMessageToThread,
      interactionState,
      closeInteraction,
      interactionBackground,
      environment,
      testIdsInQuery,
      assessmentIdInQuery,
    }
  },
)
