<template>
  <AppDialog
    v-model="isDialogOpen"
    header="Get Simulation Access Link"
    style="width: 35rem"
    @click:close="handleClose"
  >
    <AppStepperSimple
      v-model="stepIndex"
      :steps="['setup', 'link']"
      class="flex flex-col gap-4"
    >
      <template #step-1>
        <div class="flex flex-col gap-6">
          <AppDrawerFormField
            label="Candidate Loading Strategy"
            description="Select how the candidate's data will be loaded"
          >
            <AppInputDropdown
              v-model="candidateStrategy"
              :options="candidateStrategyOptions"
            />
          </AppDrawerFormField>

          <AppDrawerFormField
            label="Connection Mode"
            description="Select how the simulation attempt will be linked to an assessment or test (optional)"
          >
            <AppInputDropdown
              v-model="connectionMode"
              :options="connectionModeOptions"
              :disabled="
                testOptions.length === 0 && assessmentOptions.length === 0
              "
            />
          </AppDrawerFormField>

          <template v-if="connectionMode === 'assessment'">
            <AppDrawerFormField
              label="Assessment Connections"
              description="Select which assessments the simulation attempt will be linked to"
            >
              <AppInputMultiSelect
                v-model="connectedAssessmentIds"
                :options="assessmentOptions"
                placeholder="Select one or more assessments"
                option-value="id"
                option-label="title"
              />
            </AppDrawerFormField>
          </template>

          <template v-if="connectionMode === 'test'">
            <AppDrawerFormField
              label="Test Connections"
              description="Select which tests the simulation attempt will be linked to"
            >
              <AppInputMultiSelect
                v-model="connectedTestIds"
                :options="testOptions"
                placeholder="Select one or more tests"
                option-value="id"
                option-label="title"
              />
            </AppDrawerFormField>
          </template>
        </div>
      </template>

      <template #step-2>
        <span class="text-xs text-surface-700"
          >The simulation can be accessed from the link below</span
        >
        <AppInputText
          v-model="simulationLink"
          :append-icon="copyIcon"
          :is-append-icon-button="true"
          readonly
          @click-append-icon="handleClickCopy"
        />
      </template>
    </AppStepperSimple>
    <template #actions>
      <div class="flex justify-between items-center gap-4 w-full">
        <AppButtonTextSecondary
          label="Close"
          @click="handleConfirm"
        />
        <div class="flex justify-end items-center gap-4">
          <AppButtonSecondary
            v-if="stepIndex > 0"
            label="Back"
            @click="stepIndex--"
          />
          <AppButtonPrimary
            v-if="stepIndex < 1"
            label="Next"
            @click="nextStep"
          />
        </div>
      </div>
    </template>
  </AppDialog>
</template>

<script setup lang="ts">
import { useTestStore } from '@/stores/test'
import { useAssessmentStore } from '@/stores/assessment'
import getFullClientPath from '@/utils/getFullClientPath'

const testStore = useTestStore()
const assessmentStore = useAssessmentStore()

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  simulationId: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['update:modelValue', 'resolve', 'close'])

const isDialogOpen = computed({
  get() {
    return props.modelValue
  },
  set(value: boolean) {
    emit('update:modelValue', value)
  },
})

const connectedAssessmentIds = ref<string[]>([])
const connectedTestIds = ref<string[]>([])

const queryParams = computed(() => {
  const output: Record<string, string | string[]> = {}

  if (candidateStrategy.value === 'promptCandidate') {
    output.manual_candidate = 'true'
  }

  if (candidateStrategy.value === 'demo') {
    output.demo = 'true'
  }

  if (connectionMode.value === 'assessment') {
    output.a = connectedAssessmentIds.value
  }

  if (connectionMode.value === 'test') {
    output.t = connectedTestIds.value
  }

  return output
})

const simulationLink = useUrlBuilder(
  getFullClientPath(`simulation/${props.simulationId}`),
  queryParams,
)

function handleClose() {
  emit('close')
  isDialogOpen.value = false
}

function handleConfirm() {
  emit('resolve')
  isDialogOpen.value = false
}

const candidateStrategy = ref<'iframe' | 'promptCandidate' | 'demo'>(
  'promptCandidate',
)

const candidateStrategyOptions = [
  { label: 'Prompt Candidate', value: 'promptCandidate' },
  { label: 'Iframe', value: 'iframe' },
  { label: 'Demo', value: 'demo' },
]

const connectionMode = ref<'test' | 'assessment' | 'none'>('none')

const connectionModeOptions = computed(() => {
  const output = [{ label: 'No connection', value: 'none' }]

  if (testStore.$isInitialising || assessmentStore.$isInitialising)
    return output

  if (testOptions.value.length > 0) {
    output.push({ label: 'Test', value: 'test' })
  }

  if (assessmentOptions.value.length > 0) {
    output.push({ label: 'Assessment', value: 'assessment' })
  }

  return output
})

const assessmentOptions = computed(() => {
  if (assessmentStore.$isInitialising) return []

  return assessmentStore.getAssessmentsBySimulationId(props.simulationId)
})

const testOptions = computed(() => {
  if (testStore.$isInitialising) return []

  return testStore.getTestsBySimulationId(props.simulationId)
})

const stepIndex = ref(0)

function nextStep() {
  stepIndex.value++
}

// COPY LINK ================================================================================================
const copyIcon = ref('fa-regular fa-clone')

async function handleClickCopy() {
  await navigator.clipboard.writeText(simulationLink.value)
  copyIcon.value = 'fa-regular fa-check'

  setTimeout(() => {
    copyIcon.value = 'fa-regular fa-clone'
  }, 3000)
}
</script>
