<template>
  <MultiSelect
    v-model="selectedValues"
    :options="options"
    :option-label="
      typeof options[0] === 'object' ? (optionLabel ?? 'label') : undefined
    "
    :option-value="
      typeof options[0] === 'object' ? (optionValue ?? 'value') : undefined
    "
    :placeholder="placeholder"
    display="chip"
    :disabled="disabled"
    :loading="loading"
  >
    <template #headercheckboxicon="{ checked }">
      <font-awesome-icon
        v-if="checked"
        icon="fa-solid fa-check"
        class="text-white text-[0.7rem]"
      />
    </template>
    <template #itemcheckboxicon="{ checked }">
      <font-awesome-icon
        v-if="checked"
        icon="fa-solid fa-check"
        class="text-white text-[0.7rem]"
      />
    </template>

    <template #value="{ value }">
      <div
        v-if="value.length !== 0 && !customValueSlot"
        class="flex gap-1 justify-start items-start flex-wrap"
      >
        <AppTag
          v-for="item in value"
          :key="item"
          :color="ColorOption.Primary"
          x-button
          @click:x-button="removeItem(item)"
          >{{ getLabelFromValue(item) }}</AppTag
        >
      </div>

      <slot
        name="value"
        :value="value"
      />
    </template>

    <template #header="slotProps">
      <slot
        name="header"
        v-bind="slotProps"
      />
    </template>

    <template #option="slotProps">
      <slot
        name="item"
        v-bind="slotProps"
      />
    </template>

    <template #loadingicon>
      <AppLoadingSpinner />
    </template>
  </MultiSelect>
</template>

<script setup lang="ts">
import MultiSelect from 'primevue/multiselect'
import { ColorOption } from '~/types/Colors'

const props = defineProps({
  modelValue: {
    type: Array as PropType<any[] | null>,
    required: false,
    default: null,
  },
  options: {
    type: Array,
    required: true,
    default: () => [],
  },
  optionLabel: {
    type: String,
    required: false,
    default: null,
  },
  optionValue: {
    type: String,
    required: false,
    default: null,
  },
  placeholder: {
    type: String,
    required: false,
    default: null,
  },
  customValueSlot: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue'])

const selectedValues = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

function getLabelFromValue(value: any) {
  if (!props.options) return value
  if (typeof props.options[0] !== 'object') return value

  const option = props.options.find(
    (option: any) => option[props.optionValue ?? 'value'] === value,
  )
  return option ? option[props.optionLabel ?? 'label'] : value
}

function removeItem(item: any) {
  const newValues = selectedValues.value!.filter((value) => value !== item)
  selectedValues.value = newValues
}
</script>
